import { index,inventory, getRep, show, store, update, destroy, search, showModel, indexitems, indextherapy, indexclasss, showBalance} from '../../../api/patientBalance';

export const actions = {

    index({commit}, params = {}) {
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                commit("SET_LIST", res.data.data.patientBalances.data);
                commit("SET_BLANCE_REPORT", res.data.data.balanceRep);
                commit("UPDATE_PAGINATION",{key: 'total', value: res.data.data.patientBalances.pagination.total});
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    indexitems({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
          indexitems(params)
            .then(response => {
                commit("SET_MEDCINE",response.data.data);
                resolve(response.data);
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
    },
    getRep({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getRep(params).then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    indexclasss({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            indexclasss(params)
            .then(response => {
                commit("SET_CLASSS",response.data.data);
                resolve(response.data);
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
    },
    indextherapy({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            indextherapy(params)
            .then(response => {
                commit("SET_THERAPY",response.data.data);
                resolve(response.data);
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
    },
    showBalance({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            showBalance(params)
            .then(response => {
                commit("SET_BALANCE",response.data.data.patientBalance);
                resolve(response.data);
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
    },
    search({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
          search(params)
            .then((res) => {
              if (res.data.data.patientBalances)
                commit("SET_SEARCH_LIST", res.data.data.patientBalances);
              else commit("SET_SEARCH_LIST", []);
              resolve(res.data);
            })
            .catch((err) => {
              reject(err.response.data);
            });
        });
    },
    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
          inventory(params)
            .then(response => {
              commit('SET_INVENTORY', response.data.data.patientBalances.data);
              resolve(response.data);
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
      },    
    show({commit}, params = {}) {
        return new Promise((resolve,reject) => {
            show(params).then(res => {
                commit("SET_MODEL",res.data.data.patientBalance);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    showModel({commit},params = {}) {
        return new Promise((resolve,reject) => {
            showModel(params).then(res => {
                commit("SET_MODEL",res.data.data.patientBalance);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    store({commit},data) {
        return new Promise((resolve,reject) => {
            store(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    update({commit},data) {
        return new Promise((resolve,reject) => {
            update(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    destroy({commit},id) {
        return new Promise((resolve,reject) => {
            destroy(id).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    updateSort({commit}, sort){
        commit("SET_SORT",sort)
    },

    updateFilter({commit}, filter){
        commit('SET_FILTER', JSON.parse( JSON.stringify( filter)))
    },

    updateColumn({commit}, obj){
        commit('UPDATE_COLUMN', obj)
    },

    updatePagination({commit}, pagination){
        commit('UPDATE_PAGINATION', pagination )
    },

    refreshData({commit}){
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },


}

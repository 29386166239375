import Layout from '@/layouts'

const categoryService = {
  path: '/partnerClinicReport',
  component: Layout,
  name: 'partnerClinicReport',
  redirect: '/partnerClinicReport/index',
  children: [
    {
      path: '/partnerClinicReport/index',
      component: () => import('@/views/partnerClinicReport/index'),
      name: 'partnerClinicReport.index',
      meta: { title: 'Партнер доктор'}
    },
  ]
}

export default categoryService

<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h" style="display: inline-block">
              <span> {{ $t("message.reception_visit") }}</span>
            </div>
          </div>
        </el-col>

        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <!-- <el-button
                v-if="!old_save"
                :loading="waiting"
                :disabled="!saveBtn || !yes_user"
                @click="submit(false)"
                type="primary"
                >{{ $t("message.save") }}
              </el-button> -->

              <el-button :loading="waiting" @click="closeForm()">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-row class="p20">
      <el-col :xs="24" :sm="15" :md="15" :lg="15" :xl="15">
        <el-select
          v-model="patientServiceId"
          filterable
          :placeholder="$t('message.start_input_find_service')"
          class="autoInput"
          clearable
        >
          <template v-for="serviceParent in servicesSelected">
            <el-option-group
              v-if="serviceParent.children.length > 0"
              :key="serviceParent.id"
              :label="serviceParent.name"
            >
              <el-option
                v-for="service in getChildrens(serviceParent.children)"
                :key="service.id"
                :label="service.name"
                :value="service.id"
              >
              </el-option>
            </el-option-group>
            <el-option-group
              v-else-if="
                serviceParent.is_service == true &&
                serviceParent.parent_id == null
              "
              :key="serviceParent.id"
            >
              <el-option :label="serviceParent.name" :value="serviceParent.id">
              </el-option>
            </el-option-group>
          </template>
        </el-select>
      </el-col>
      <!-- end col -->

      <el-col :xs="24" :sm="9" :md="9" :lg="9" :xl="9">
        <el-button
          style="font-size: 14px !important; padding: 12px !important"
          @click="addService"
          >{{ $t("message.add") }}</el-button
        >
        <el-button
          style="font-size: 14px !important; padding: 12px !important"
          @click="drawerPatientService = true"
          >{{ $t("message.add_service") }}</el-button
        >
        <el-button
          v-if="contractServiceList.length != 0 && form.contract_id"
          style="font-size: 14px !important; padding: 12px !important"
          @click="AddContactServices"
          >{{ $t("message.subject_of_agreement") }}</el-button
        >
      </el-col>
      <!-- end col -->

      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <table class="table table-striped table-bordered mt-4">
          <thead>
            <tr>
              <th>{{ $t("message.pp") }}</th>
              <th class="wi-50">{{ $t("message.name") }}</th>
              <th class="wi-7">{{ $t("message.express") }}</th>
              <th class="wi-7">{{ $t("message.quantity_short") }}</th>
              <th class="wi-7">{{ $t("message.price2") }}</th>
              <th class="wi-7">{{ $t("message.discount_percent") }}</th>
              <th class="wi-7">{{ $t("message.discount_price") }}</th>
              <th class="wi-7">{{ $t("message.price2") }}</th>
              <th class="wi-7">{{ $t("message.delete") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(ser, index) in list"
              :key="'order-create-in-header' + index"
            >
              <td>{{ index + 1 }}</td>
              <td>
                {{ ser.name }}
              </td>
              <td>
                <div class="d-flex">
                  <el-checkbox
                    @input="editRow(ser, $event, 'is_express')"
                    :value="ser.is_express"
                    size="small"
                    v-if="express.length"
                  />
                  <div class="px-2">
                    {{ express.length ? express[0].percent + " %" : "" }}
                  </div>
                </div>
              </td>
              <td>
                <el-input
                  type="number"
                  id="usr"
                  size="small"
                  step="any"
                  @input="editRow(ser, $event, 'count')"
                  :value="ser.count"
                  :min="1"
                ></el-input>
              </td>
              <td>
                {{ ser.price | formatMoney }}
              </td>

              <td>
                <el-input-number
                  :disabled="disableDiscount()"
                  type="number"
                  size="small"
                  @change="editRow(ser, $event, 'discount_price')"
                  controls-position="right"
                  :value="discountService(ser.discount_price)"
                  :min="0"
                  :max="100"
                ></el-input-number>
              </td>
              <td>
                {{
                  Math.round(
                    (discountService(ser.discount_price) * ser.price) / 100
                  ) | formatMoney
                }}
              </td>
              <td>
                {{
                  Math.round(
                    ser.count *
                      expressPrice(ser.is_express) *
                      (ser.price -
                        (ser.price * discountService(ser.discount_price)) / 100)
                  ) | formatMoney
                }}
              </td>
              <td>
                <el-button
                  class="d-block"
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  @click.native.prevent="deleteRow(ser)"
                >
                </el-button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <th colspan="5">{{ $t("message.total_0") }}</th>
            <th class="w-7">{{ totalDiscount | formatMoney }}</th>
            <th colspan="2">{{ Math.round(totalAmount) | formatMoney }}</th>
          </tfoot>
        </table>
      </el-col>
      <div>
        <div
          class="workers-info madal-info-workers p-5 pt-2 pb-2"
          v-loading="fileLoader"
        >
          <!-- <el-upload
            class="upload-demo mu-styl-doc"
            action="/"
            :on-preview="handlePreview"
            :on-change="createDocumentList"
            :file-list="documentList"
            :auto-upload="false"
            ref="file"
          >
            <el-button size="small" type="primary">
              Добавить документ
            </el-button>

            <div slot="tip" class="el-upload__tip">
              {{ $t("message.supported_formats") }}
              <br />{{ $t("message.size_limit_5") }}
            </div>
          </el-upload> -->

          <input
            type="file"
            class="upload-demo mu-styl-doc"
            @change="onChangeProspectus"
            ref="file"
          />
        </div>
        <el-button
          class="seva-document"
          type="success"
          size="small"
          @click="submitt()"
        >
          Сохранить
        </el-button>
      </div>
    </el-row>
    {{ excel_datas }}
    <div class="progress">
      <div
        class="bar"
        :style="'height:30px;background:blue;width:' + progress + '%'"
      ></div>
    </div>
    <el-drawer
      class="popups_title"
      :wrapperClosable="false"
      :append-to-body="true"
      :visible.sync="drawerPatientService"
      direction="rtl"
      size="70%"
    >
      <CreatePatientService
        :driver="drawerPatientService"
        @c-close="closeDrawerPatientService"
      ></CreatePatientService>
    </el-drawer>
    <el-drawer
      title="Изменить тег"
      :visible.sync="drawerShow"
      size="70%"
      :drawer="drawerShow"
      @open="reopenShow = true"
      :append-to-body="true"
      @close="reopenShow = false"
    >
      <div>
        <crm-show
          :reloadModel="reopenShow"
          @open="reopenShow = true"
          @c-close="reloadIfChanged('drawerShow')"
          @closed="emptyModel"
        ></crm-show>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import CrmShow from "./crm-show";
import order from "@/utils/mixins/order";
import list from "@/utils/mixins/list";
import users from "@/components/selects/user_list";
import drawer from "@/utils/mixins/drawer";

export default {
  mixins: [order, drawer, list],
  props: ["currentPatient"],
  components: {
    CrmShow,
    users,
  },
  data() {
    return {
      RoomName: "",
      RoomNumber: "",
      old_save: false,
      old_order: {},
      drawerShow: false,
      reopenShow: false,
      saveBtn: true,
      printModel: {},
      express_check: false,
      reopenUpdate: false,
      reloadList: false,
      room: null,
      totalSteps: 10,
      stepsDone: 5,
      file_event: null,
      fileLoader: false,
      documentList: [],
      excel_datas: [],
      //   drawer: {
      //         create: {
      //             name: "create",
      //             status: false,
      //             component: 'componentDrawerCreate'
      //         },
      //   }
    };
  },
  watch: {
    open: {
      handler: function (newVal, oldVal) {
        if (newVal === true) {
          this.setNewOrderData();
        }
      },
      deep: true,
      immediate: true,
    },
    conditions: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.form.condition_id = this.conditions[0].id;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      contractServiceList: "contracts/contract_services",
      // model: "orders/relation_model",
      workTimes: "workTimes/inventory",
      express: "expresses/list",
    }),
  },
  mounted() {
    if (this.workTimes && this.workTimes.length === 0) {
      this.setworkTimes();
    }
    if (this.express && this.express.length === 0) this.loadExpress();
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions({
      setworkTimes: "workTimes/inventory",
      loadExpress: "expresses/index",
      editPatient: "patients/show",
      editService: "services/show",
      servicesRealtionList: "services/relationList",
      contractServices: "contracts/contractServices",
      discountPatient: "discountPatients/show",
      // empty_conclusions: "conclusions/empty",
      showModel: "conclusions/show",
      save: "orders/store",
      update: "orders/update",
      massOrder: "orders/massOrder",
      saveList: "services/setlist",
    }),
    parentEvent(value) {
      this.room = this.workTimes.filter((val) => {
        return val.day == this.form.day.getDay() && val.doctor_id == value;
      });
    },
    async show(model) {
      this.$loadingCursor("wait");
      await this.showModel(model.id)
        .then((res) => {
          this.$loadingCursor("default");
          this.drawerShow = true;
        })
        .catch((err) => {
          this.$loadingCursor("default");
        });
    },
    progress() {
      return (this.stepsDone / this.excel_datas.length) * 100;
    },
    disableDiscount() {
      if (
        this.discountPatients.length &&
        this.discountPatients[0].discount != 0 &&
        this.discountPatients[0].status
      ) {
        return true;
      } else return false;
    },
    createDocumentList(file) {
      this.documentList.push(file.raw);
    },
    handlePreview(file) {
      window.open(file.url, "_blank");
    },
    onChangeProspectus(e) {
      console.log(e);
      var files = e.target.files,
        f = files[0];
      this.file_event = f;
    },
    handleRemove(file, doc) {
      if (
        file.hasOwnProperty("id") &&
        this.$route.params.id &&
        !this.fileLoader
      ) {
        this.fileLoader = true;
        this.deleteStaffDocument({
          staff_id: this.$route.params.id,
          file_id: file.id,
        })
          .then((res) => {
            this.fileLoader = false;
            if (res.status == 200) {
              this.getDocuments();
            }
            this.$alert(res);
          })
          .catch((error) => {
            this.$alert(error);
            this.fileLoader = false;
          });
      } else {
        this.getDocuments();
      }
    },
    submitt() {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(this.file_event);
      fileReader.onload = (event) => {
        let data = event.target.result;
        let workbook = XLSX.read(data, { type: "binary" });
        workbook.SheetNames.forEach((sheet) => {
          let row0bject = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheet]
          );
          this.excel_datas.push(JSON.stringify(row0bject));
        });
      };

      this.massOrder(this.excel_datas).then((res) => {
        console.log(res);
      });
    },
    expressPrice(is_express) {
      if (is_express) {
        return 1 + this.express[0].percent / 100;
      } else {
        return 1;
      }
    },
    discountService(sevice_discount) {
      if (
        this.discountPatients.length &&
        this.discountPatients[0].discount != 0 &&
        this.discountPatients[0].status
      ) {
        this.list.forEach((element) => {
          element.discount_price = this.discountPatients[0].discount;
        });
        return this.discountPatients[0].discount;
      } else return sevice_discount;
    },
    fetchData() {
      this.loadingData = true;
      const query = { relation: true };
      this.servicesRealtionList(query)
        .then((res) => {
          this.loadingData = false;
        })
        .catch((err) => {});
    },
    setNewOrderData() {
      this.form = JSON.parse(JSON.stringify(this.model));
      this.form.day = new Date();
    },
    submit(close) {
      this.changeWaiting(true);
      this.form.patient_id = this.user.id;
      this.form.services = this.list;
      this.save(this.form)
        .then((res) => {
          this.old_save = true;
          this.old_order = res.data.order;
          this.changeWaiting();
          this.saveBtn = false;
          this.printModel = res.data ? res.data.order : null;
          this.$alert(res);
        })
        .catch((err) => {
          this.changeWaiting();
          this.ifValidationError(err);
        });
    },
    updatingOrder(close) {
      this.changeWaiting(true);
      this.form.id = this.old_order.id;
      this.form.patient_id = this.user.id;
      this.form.services = this.list;

      this.update(this.form)
        .then((res) => {
          this.changeWaiting();
          this.saveBtn = false;
          this.printModel = res.data ? res.data.order : null;
          this.$alert(res);
        })
        .catch((err) => {
          this.changeWaiting();
          this.ifValidationError(err);
        });
    },
    tabEvent(event) {
      if (
        event.name == "second" &&
        this.form.contract_id &&
        this.contractServiceList.length == 0
      ) {
        this.contractServices({
          id: this.form.contract_id,
          contract_service: true,
        })
          .then((res) => {})
          .catch((err) => {});
      }
    },
    AddContactServices() {
      this.saveList(this.contractServiceList.services)
        .then((res) => {})
        .catch((err) => {});
    },
    closeForm() {
      this.old_order = {};
      this.old_save = false;
      this.empty();
      this.$emit("c-close", { reload: false, drawer: this.drawer });
    },
    resetForm() {
      this.setNewOrderData();
      this.user = {};
      this.noUser();
      this.emptySearch();
      this.emptyList();
      this.old_order = {};
      this.old_save = false;
      this.search = "";
      this.saveBtn = true;
      this.latestServices.order_services = [];
      this.discountPatients = [];
    },
    empty() {
      this.activeTab = "first";
      this.emptyList();
      this.emptySearch();
      this.noUser();
      this.user = null;
      this.patientServiceId = "";
      this.search = "";
      this.saveBtn = true;
      this.latestServices.order_services = [];
      this.discountPatients = [];
    },
    listChanged() {
      this.reloadList = true;
    },
    parent() {
      return this.$parent.$parent;
    },
    emptyModel() {
      this.empty_conclusions();
    },
  },
};
</script>
<style lang="scss">
.tableKa .el-table_1_column_7 .cell {
  display: block !important;
}

.bemor_uslugalar {
  text-decoration: none;
  list-style: none;
  margin: 0px;
  padding: 0px;
  li {
    border-bottom: 1px solid #dcdfe6;
    line-height: 19px;
    padding: 5px 0;
  }
  div {
    font-weight: 600;
  }
  span {
    text-align: right;
    width: 100%;
    display: block;
  }
  .col__red span {
    color: red;
  }
}
.ow_hiddin {
  height: 50vh;
  overflow-y: scroll;
}
.ow_hiddin::-webkit-scrollbar {
  width: 8px;
}

.ow_hiddin::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #fff;
}

.ow_hiddin::-webkit-scrollbar-thumb {
  background-color: #1f75a8;
  border-radius: 20px;
}
.my_add_pats {
  margin: 10px 0 20px 0;
}
.new_room {
  display: flex;
  width: 100%;
  align-items: center;
  height: 50px;
  /* background: #dcdfe6; */
  border-bottom: 1px solid #dcdfe6;
}
.room_number_title {
  margin-left: 10px;
  font-weight: 600;
}
</style>
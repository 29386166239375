<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || 'Organization'"
    filterable
    clearable
    
  >
    <el-option v-for="item in organizations" :key="item.id" :label="item.company_name" :value="item.id"></el-option>
  </el-select>

   <!-- <el-drawer 
   class="popups_title" 
   :append-to-body="true"  
   :visible.sync="drawerPartner"  
   direction="rtl" size="40%"
   >
      <AddPartnerDoctor @c-close="closeDrawerAddPartnerDoctor"></AddPartnerDoctor>
  </el-drawer> -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import inventory from '@/utils/mixins/components/inventory';
// import AddPartnerDoctor from '@/views/scheduler/add-partner-doctor';

export default {
  mixins: [inventory],
  // components: {
  //   AddPartnerDoctor,
  // },
  props: ['organization_id'],
  mounted() {
    if (this.organizations && this.organizations.length === 0) {
      this.getInventory();
    }
  },
  watch: {
    organization_id:{
       handler: function (val, oldVal) { 
          this.selected = val;
          this.$emit("input", this.selected);
       },
       deep: true,
       immediate: true
    }
  },
  computed: {
    ...mapGetters({
      organizations: 'organizations/inventory',
     
    }),
  },
  methods: {
    ...mapActions({
      getInventory: 'organizations/inventory',
     
    })
  },
 
};
</script>

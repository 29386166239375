import Layout from '@/layouts'

const paymentType = {
    path: '/paymentTypes',
    component: Layout,
    name: 'paymentTypes',
    redirect: '/paymentTypes/index',
    children: [
      {
        path: '/paymentTypes/index',
        component: () => import('@/views/paymentType/index'),
        name: 'paymentTypes.index',
        meta: { title: 'Тип оплаты'}
      },
    ]
  }

export default paymentType

import request from './../utils/request';

export function index(params) {
  return request({
    url: '/products',
    method: 'GET',
    params
  });
}

export function show(id) {
  return request({
    url: '/products/' + id,
    method: 'GET'
  });
}

export function store(data) {
  return request({
    url: '/products',
    method: 'POST',
    data
  });
}

export function update(data) {
  return request({
    url: '/products/' + data.id,
    method: 'PUT',
    data
  });
}

export function destroy(id) {
  return request({
    url: '/products/' + id,
    method: 'DELETE'
  });
}

export function inventory(params) {
  return request({
    url: '/products',
    method: 'GET',
    params
  });
}
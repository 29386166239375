import Layout from '@/layouts'

const city = {
  path: '/printAudit',
  component: Layout,
  name: 'printAudit',
  redirect: '/printAudit/index',
  children: [{
    path: '/printAudit/index',
    component: () => import('@/views/printAuditable/index'),
    name: 'printAudit.index',
    meta: {
      title: 'Города'
    }
  }, ]
}

export default city
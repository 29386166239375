import Layout from '@/layouts'

const condition = {
    path: '/conditions',
    component: Layout,
    name: 'conditions',
    redirect: '/conditions/index',
    children: [
      {
        path: '/conditions/index',
        component: () => import('@/views/condition/index'),
        name: 'conditions.index',
        meta: { title: 'Состояние'}
      },
    ]
  }

export default condition

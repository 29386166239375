import Layout from '@/layouts'

const specialty = {
  path: '/district',
  component: Layout,
  name: 'district',
  redirect: '/district/index',
  children: [{
    path: '/district/index',
    component: () => import('@/views/district/index'),
    name: 'district.index',
    meta: {
      title: 'Регион'
    }
  },]
}

export default specialty
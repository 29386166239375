import Layout from '@/layouts'

const schedule = {
  path: '/formLogo',
  component: Layout,
  name: 'formLogo',
  redirect: '/formLogo/index',
  children: [{
    path: '/formLogo/index',
    component: () => import('@/views/formLogo/index'),
    name: 'formLogo.index',
    meta: {
      title: 'График'
    }
  }, ]
}

export default schedule
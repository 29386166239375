import Layout from '@/layouts'

const specialty = {
  path: '/region',
  component: Layout,
  name: 'region',
  redirect: '/region/index',
  children: [{
    path: '/region/index',
    component: () => import('@/views/region/index'),
    name: 'region.index',
    meta: {
      title: 'Регион'
    }
  }, ]
}

export default specialty
import request from "./../utils/request";

export function excelReport(params) {
    return request({
        url: "/excel-report",
        method: "get",
        responseType: 'blob', 
        params       
    });
}

export function excelReport2(params) {
    return request({
        url: "/excel-report2",
        method: "get",
        responseType: 'blob', 
        params       
    });
}

export function excelReport3(params) {
    return request({
        url: "/excel-report3",
        method: "get",
        responseType: 'blob', 
        params       
    });
}
import Layout from '@/layouts'

const doctorProcessReport = {
  path: '/doctorProcessReport',
  component: Layout,
  name: 'doctorProcessReport',
  redirect: '/doctorProcessReport/index',
  children: [{
    path: '/doctorProcessReport/index',
    component: () => import('@/views/doctorProcessReport/index'),
    name: 'doctorProcessReport.index',
    meta: {
      title: 'Отчет'
    }
  }, ]
}

export default doctorProcessReport
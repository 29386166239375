<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || 'Партнёр доктор'"
    filterable
    clearable
    :size="size"
  >
    <el-option
      v-for="item in filtered"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";

export default {
  mixins: [inventory],
  props: ["partner_clinic_id", "id"],
  mounted() {
    if (this.doctors && this.doctors.length === 0) {
      this.getInventory({ column: "name", order: "asc" });
    }
  },
  watch: {
    partner_clinic_id: {
      handler: function (val, oldVal) {
        this.getInventory({ column: "name", order: "asc" });
        this.selected = null;
        this.$emit("input", this.selected);
      },
    },
    id: {
      handler: function () {
        setTimeout(() => {
          this.selected = this.id;
        });
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      doctors: "partnerDoctor/inventory",
    }),
    filtered: function () {
      if (this.partner_clinic_id) {
        return this.doctors.filter((o) => {
          return o.partner_clinic_ids.includes(this.partner_clinic_id);
        });
      } else {
        return this.doctors;
      }
    },
  },
  methods: {
    ...mapActions({
      getInventory: "partnerDoctor/inventory",
    }),
  },
};
</script>

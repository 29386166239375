import Layout from '@/layouts'

const currency = {
  path: '/currencies',
  component: Layout,
  name: 'currencies',
  redirect: '/currencies/index',
  children: [
    {
      path: '/currencies/index',
      component: () => import('@/views/currency/index'),
      name: 'currencies.index',
      meta: { title: 'Валюты'}
    },
  ]
}

export default currency

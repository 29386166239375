import Layout from '@/layouts'


const discountPatients = {
  path: '/discountPatient',
  component: Layout,
  name: 'discountPatient',
  redirect: '/discountPatient/index',
  children: [
    {
      path: '/discountPatient/index',
      component: () => import('@/views/discountPatient/index'),
      name: 'discountPatient.index',
      meta: { title: 'Города'}
    },
  ]
}
export default discountPatients
import Layout from '@/layouts'

const specialty = {
  path: '/process',
  component: Layout,
  name: 'process',
  redirect: '/process/index',
  children: [{
    path: '/process/index',
    component: () => import('@/views/process/index'),
    name: 'process.index',
    meta: {
      title: 'Процесс'
    }
  }, ]
}

export default specialty
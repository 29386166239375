import { sort } from "../properties/sort";
import { filter } from "./properties/filter";
import { pagination } from "../properties/pagination";
import { model } from "./properties/model";
import { i18n } from '@/utils/i18n';


export const mutations = {
  SET_LIST: (state, patients) => (state.list = patients),
  SET_LAST_ID: (state, lastId) => (state.lastId = lastId),
  INCREMENT_LAST_ID: (state) => (++state.lastId),
  SET_INVENTORY: (state, patients) => (state.inventory = patients),
  SET_PATINETS_LIST: (state, patients) => (state.patients_list = patients),
  SET_SEARCH_LIST: (state, patients) => (state.search_list = patients),
  SET_FILTER: (state, filter) => (state.filter = filter),
  SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
  SET_SORT: (state, sort) => (state.sort = sort),
  SET_MODEL: (state, model) => {
    state.model = model;
  },
  SET_LAST_MODEL_ID: (state, id) => (state.last_model_id = id),
  UPDATE_COLUMN: (state, obj) => {
    state.columns[obj.key].show = obj.value;
  },
  UPDATE_SORT: (state, sort) => {
    state.sort[sort.column] = sort.order;
  },
  UPDATE_PAGINATION: (state, pagination) => {
    state.pagination[pagination.key] = pagination.value;
  },
  EMPTY_MODEL: (state) => {
    state.model = JSON.parse(JSON.stringify(model));
  },
  REFRESH: (state) => {
    state.filter = JSON.parse(JSON.stringify(filter));
    state.sort = JSON.parse(JSON.stringify(sort));
    state.pagination = JSON.parse(JSON.stringify(pagination));
  },
  EMPTY_SEARCH: (state) => {
    state.search_list = [];
  },
  UPDATE_LANG_COLUMN: (state) => {
            state.columns.id.title = "№";
            state.columns.first_name.title = i18n.t('message.first_name');
            state.columns.surname.title = i18n.t('message.surname');
            state.columns.patronymic.title = i18n.t('message.patronymic');
            state.columns.phone.title = i18n.t('message.phone');
            state.columns.born_date.title = i18n.t('message.dob');
            state.columns.organization_id.title = i18n.t('message.organization');
            state.columns.document_type_id.title = i18n.t('message.type_documents');
            state.columns.series_and_number.title = i18n.t('message.series_and_number');
            state.columns.date_of_issue.title = i18n.t('message.date_of_issue');
            state.columns.authority.title = i18n.t('message.authority');
            state.columns.state_id.title = i18n.t('message.country');
            state.columns.city_id.title = i18n.t('message.city');
            state.columns.region.title = i18n.t('message.region');
            state.columns.region_id.title = i18n.t('message.region');
            state.columns.street.title = i18n.t('message.street');
            state.columns.area.title = i18n.t('message.district');
            state.columns.home.title = i18n.t('message.house');
            state.columns.flat.title = i18n.t('message.flat');
            state.columns.social_id.title = i18n.t('message.social');
            state.columns.code.title = i18n.t('message.code_id');
            state.columns.inn.title = i18n.t('message.inn');
            state.columns.onlis.title = i18n.t('message.onlis');
            state.columns.gender.title = i18n.t('message.gender');
            state.columns.created_at.title = i18n.t('message.date');
            state.columns.updated_at.title = i18n.t('message.update_date');
            state.columns.settings.title = i18n.t('message.settings');
  }
};

import Layout from '@/layouts'

const paymentType = {
  path: '/currenciesWarehouses',
  component: Layout,
  name: 'currenciesWarehouses',
  redirect: '/currenciesWarehouses/index',
  children: [{
    path: '/currenciesWarehouses/index',
    component: () => import('@/views/currenciesWarehouses/index'),
    name: 'currenciesWarehouses.index',
    meta: {
      title: 'Тип Валюта'
    }
  }, ]
}

export default paymentType
import Layout from '@/layouts'

const state = {
    path: '/states',
    component: Layout,
    name: 'states',
    redirect: '/states/index',
    children: [
      {
        path: '/states/index',
        component: () => import('@/views/state/index'),
        name: 'states.index',
        meta: { title: 'Страны'}
      },
    ]
  }

export default state

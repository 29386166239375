// loadingButton, form, 
// add function: 
// 1. this.parent().listChanged()
export default {
    // props: ['drawer'],
    data() {
        return {
            loadingButton: false,
            form: {},
        }
    },
    computed: {},
    created() {
        if (this.model != null) {
            this.form = JSON.parse(JSON.stringify(this.model));
        }
    },
    methods: {
        parent() {
            return this.$parent.$parent
        },
        reloadForm() {
            if (this.model != null) {
                this.form = JSON.parse(JSON.stringify(this.model));
            }
        }
    },
    watch: {
        model: {
            handler: function (newVal, oldVal) {
                this.reloadForm();
            },
            deep: true,
            immediate: true
        }
    }
}
import Layout from '@/layouts'

const paymentReport = {
    path: '/paymentReport',
    component: Layout,
    name: 'paymentReport',
    redirect: '/paymentReport/index',
    children: [
      {
        path: '/paymentReport/index',
        component: () => import('@/views/paymentReport/index'),
        name: 'paymentReport.index',
        meta: { title: 'Тип оплаты'}
      },
    ]
  }

export default paymentReport
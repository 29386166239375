import Layout from '@/layouts'

const hour = {
    path: '/hours',
    component: Layout,
    name: 'hours',
    redirect: '/hours/index',
    children: [
      {
        path: '/hours/index',
        component: () => import('@/views/hour/index'),
        name: 'hours.index',
        meta: { title: 'Времени'}
      },
    ]
  }

export default hour

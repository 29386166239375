<template>
  <div>
  <el-select
    v-if="!disab"
    @change="clearx()"
    :value="selected"
    @input="dispatch"
    :placeholder="plc || 'Главные Склады'"
    filterable
    clearable
  >
    <el-option v-for="item in warehouses" :key="item.id" :label="item.name" :value="item.id"></el-option>
  </el-select>
  <el-select
    v-else
    @change="clearx()"
    :value="selected"
    @input="dispatch"
    :placeholder="plc || 'Главные Склады'"
    filterable
    clearable
    disabled
  >
    <el-option v-for="item in warehouses" :key="item.id" :label="item.name" :value="item.id"></el-option>
  </el-select>
  </div>

  
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import inventory from '@/utils/mixins/components/inventory';

export default {
  mixins: [inventory],
  props: ['mainwarehouse_id'],
  data() {
    return {
        disab: false
    };
  },
  mounted() {
    if (this.warehouses && this.warehouses.length === 0) {
      this.getInventory();
    }
  },
  watch: {
    mainwarehouse_id:{
       handler: function (val, oldVal) { 
          this.selected = val;
          this.$emit("input", this.selected);
       },
       deep: true,
       immediate: true
    }
  },
  computed: {
    ...mapGetters({
      warehouses: 'warehouses/maininventory',
    }),
  },
  methods: {
    ...mapActions({
      getInventory: 'warehouses/maininventory',
    }),
    clearx(){
      this.$emit('clear-x')
    },
    disableSelect() {
      this.disab = true
    }
  },
};
</script>

import Vue from "vue";
import App from "./App.vue";
import "./permission"; // permission control
import router from "./router";
import store from "./store";
import "@/directive/permission/index";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/utils/library";
import * as filters from "./filters"; // global filters
import CKEditor from '@ckeditor/ckeditor5-vue';
import {i18n} from '@/utils/i18n';
window._ = require("lodash");

// import 'el-select-tree/lib/element-ui';
// import ElSelectTree from 'el-select-tree';


// Vue.use(ElSelectTree);

const files = require.context("./components/", true, /\.vue$/i);
files
  .keys()
  .map((key) =>
    Vue.component(key.split("/").pop().split(".")[0], files(key).default)
  );

/**
 * import Inputmask from 'inputmask'
 */
import Inputmask from "inputmask";
Vue.directive("mask", {
  bind: function (el, binding) {
    Inputmask(binding.value).mask(el.getElementsByTagName("INPUT")[0]);
  },
});

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

new Vue({
    router,
    store,
    Inputmask,
    i18n,
    render: function(h) { return h(App) }
}).$mount('#app')

// import Layout from '@/layouts'

// const schedule = {
//   path: '/bunk',
//   component: Layout,
//   name: 'bunk',
//   redirect: '/bunk/index',
//   children: [{
//     path: '/bunk/index',
//     component: () => import('@/views/bunk/index'),
//     name: 'bunk.index',
//     meta: {
//       title: 'График'
//     }
//   }, ]
// }

// export default schedule
import Layout from '@/layouts'

const schedule = {
  path: '/bunk',
  component: Layout,
  name: 'bunk',
  redirect: '/bunk/main',
  children: [{
    path: '/bunk',
    component: () => import('@/views/bunk/index'),
    name: 'bunk.index',
    meta: {
      title: ''
    },
    redirect: '/bunk/main',
    children: [{

        path: '/bunk/main',
        component: () => import('@/views/bunk/housing'),
        name: 'housing',
        meta: {
          title: ''
        }

      }, {

        path: '/bunk/floor',
        component: () => import('@/views/bunk/floor'),
        name: 'floor',
        meta: {
          title: ''
        }

      },
      {

        path: '/bunk/class',
        component: () => import('@/views/bunk/class'),
        name: 'class',
        meta: {
          title: ''
        }

      },
      {

        path: '/bunk/hospitalRooms',
        component: () => import('@/views/bunk/hospitalRooms'),
        name: 'hospitalRooms',
        meta: {
          title: ''
        }

      },
      // {

      //   path: '/bunk/hospitalRooms/withbunk',
      //   component: () => import('@/views/schedulesCabinet/index'),
      //   name: 'schedulesCabinet',
      //   meta: {
      //     title: ''
      //   }

      // },
      {

        path: '/bunk/bunk',
        component: () => import('@/views/bunk/bunk'),
        name: 'bunk',
        meta: {
          title: ''
        }

      },
      {

        path: '/bunk/departments',
        component: () => import('@/views/bunk/departments'),
        name: 'departments',
        meta: {
          title: ''
        }

      },
      {

        path: '/bunk/departmentUser',
        component: () => import('@/views/bunk/departmentUser'),
        name: 'departmentUser',
        meta: {
          title: ''
        }

      },
      {

        path: '/bunk/hospitalizationType',
        component: () => import('@/views/bunk/hospitalizationType'),
        name: 'hospitalizationType',
        meta: {
          title: ''
        }

      },
      {

        path: '/bunk/purposHospitalization',
        component: () => import('@/views/bunk/purposHospitalization'),
        name: 'purposHospitalization',
        meta: {
          title: ''
        }

      },
      {

        path: '/bunk/typeInjury',
        component: () => import('@/views/bunk/typeInjury'),
        name: 'typeInjury',
        meta: {
          title: ''
        }

      },
      {

        path: '/bunk/injuryStatus',
        component: () => import('@/views/bunk/injuryStatus'),
        name: 'injuryStatus',
        meta: {
          title: ''
        }

      },
      {

        path: '/bunk/patentIndicator',
        component: () => import('@/views/bunk/patentIndicator'),
        name: 'patentIndicator',
        meta: {
          title: ''
        }

      }

    ]
  }, ]
}

export default schedule
import Layout from '@/layouts'

const city = {
  path: '/cities',
  component: Layout,
  name: 'cities',
  redirect: '/cities/index',
  children: [
    {
      path: '/cities/index',
      component: () => import('@/views/city/index'),
      name: 'cities.index',
      meta: { title: 'Города'}
    },
  ]
}

export default city
import Layout from '@/layouts'

const roomsRouter = {
  path: '/rooms',
  component: Layout,
  name: 'rooms',
  redirect: '/rooms/index',
  children: [
    {
      path: '/rooms/index',
      component: () => import('@/views/rooms/index'),
      name: 'rooms.index',
      meta: { title: 'rooms'}
    }
  ]
}

export default roomsRouter
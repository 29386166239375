import Layout from '@/layouts'

const material = {
  path: '/material',
  component: Layout,
  name: 'material',
  redirect: '/material/index',
  children: [{
    path: '/material/index',
    component: () => import('@/views/material/index'),
    name: 'material.index',
    meta: {
      title: 'График'
    }
  }, ]
}

export default material

export const state = {
    schedulePatients: [],
    stasionaryPatients: [],
    paymentsSum: [],
    expences: [],
    contractsCount: [],
    formTemplates: [],
    patientScheduleCount: [],
    stasionaryPayments: [],
    warehouseCount: [],
    number_of_orders_today: [],
    number_of_orders_month: [],
};

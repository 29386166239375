<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || 'Склад'"
    filterable
    clearable
    :size="size"
    class="d-block"
  >
    <el-option 
      v-for="warehouse in warehouses"
      :key="warehouse.id"
      :label="warehouse.name"
      :value="warehouse.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import inventory from '@/utils/mixins/components/inventory';

export default {
  mixins: [
    inventory
  ],
  mounted() {
    if (this.warehouses && this.warehouses.length === 0) {
      this.getInventory();
    }
  },
  props: [
    'warehouse_id'
  ],
  watch: {
    warehouse_id: {
      handler: function () {
        this.dispatch(this.warehouse_id);
      },
      deep: true,
      immediate: true
    },
  },
  computed: {
    ...mapGetters({
      warehouses: 'warehouses/inventory'
    })
  },
  methods: {
    ...mapActions({
      getInventory: 'warehouses/inventory'
    })
  }
}
</script>